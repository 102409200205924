<template>
  <div class="not-found">
    <h2>正在开发中...</h2>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
  .not-found {
    font-size: 40px;
    height: 500px;
    line-height: 500px;
    text-align: center;
  }
</style>
