<template>
  <div class="home__intro">
    <div class="home__intro__inner">
      <div class="card">
        <div class="left"></div>
        <div class="right">
          <p class="title">青年空间</p>
          <p class="text">青年空间是由青年人支持和运营，供青年人进行公共对话、文化沙龙、电影放映、主题派对、共享居住等活动的场地。</p>
          <p class="text">青年空间的使命是在实体和精神上将年轻人聚集在一起，通过对空间功能更多可能性的探索，让青年人认识自己、改变自己、提升自己，让青年人在这里找到真正的精神归属。</p>
          <!-- <div class="btn">了解更多</div> -->
        </div>
      </div>
      <div class="content">
        <p class="title">706青年空间</p>
        <p class="text active">706青年空间起源于宇宙中心五道口，是中国第一家青年空间。706已经举办了上千场活动，发起了数十个创新项目，世界各地有趣好玩的青年人在此汇流。</p>
        <p class="text">我们试图创造一种新型的实体空间形态，目前在广州，上海，深圳，杭州，成都，南京等地都在落地孵化本地的706实体据点，同时，我们通过社群式的学习，倡导一种新的生活方式和教育理念，让青年人探索生活的更多可能性，并且同时孵化具有潜力的706共创项目。</p>
      </div>
      <div class="list">
        <div class="left">
          <div class="col-2">
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--theatre">
                  <div class="list-text">
                    <p class="title">706小剧场</p>
                    <p class="text">在这里，我们探索话剧形式的新的可能，诗歌音乐文学也会自然而然在这发生，使得这里的戏剧形式更加多样化，更具备实验性</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--salon">
                  <div class="list-text">
                    <p class="title">706沙龙空间</p>
                    <p class="text">这里是706举办各种沙龙的地方，既有文化圈大咖的沙龙分享，也有深夜秉烛夜谈的“一千零一夜”，这个空间，承载了706持续多年的沙龙历史</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="big">
              <div class="list-inner">
                <div class="list-content list-content--coffee">
                  <div class="list-text">
                    <p class="title">706咖啡馆</p>
                    <p class="text">这个属于706最开放的公共空间，类似古希腊的雅典广场，大伙在这伴着咖啡和简餐，休息，聊天，办公</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1">
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--rooftop">
                  <div class="list-text">
                    <p class="title">706天台花园</p>
                    <p class="text">这是整个706视野最开阔的地方，你可以眺望整个中关村和鸟巢，我们在这种花养猫，看日出，搭帐篷夜聊，这里是706最神奇的区域</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--canteen">
                  <div class="list-text">
                    <p class="title">706花生食堂</p>
                    <p class="text">我们在这里围坐着边吃饭边聊天，我们在这里深夜聚餐，这里是706最家长里短，充满烟火气的地方。</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--pub">
                  <div class="list-text">
                    <p class="title">706断裂酒馆</p>
                    <p class="text">这是北京第一个合作社酒，这是各类失败者的联合。有人说，断裂酒馆就是喝断片儿了，忒对了，喝断片儿了，你肯定就断裂了。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="col-2">
            <div class="big">
              <div class="list-inner">
                <div class="list-content list-content--library">
                  <div class="list-text">
                    <p class="title">706图书馆</p>
                    <p class="text">这个是706唯一一个最安静的空间，藏有5000+文史哲类的图书，小伙伴在这里读书，写作，思考，摆脱日常的束缚，进入沉浸式的思考</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--labor">
                  <div class="list-text">
                    <p class="title">706生活实验室</p>
                    <p class="text">706旗下一个长租的共享居住产品，一群小伙伴采取自治和共创的模式，共同居住和学习成长，已经在北上广深开辟据点</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--hotel">
                  <div class="list-text">
                    <p class="title">706青旅旅舍</p>
                    <p class="text">这是本部的一个附属功能空间，拥有5个房间，给临时来706参观或参加活动或来北京实习的年轻人提供短期住宿</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1">
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--living">
                  <div class="list-text">
                    <p class="title">706共享客厅</p>
                    <p class="text">是706发起的私人客厅开放共享计划，鼓励个人将自家的客厅周末开放出来，举办沙龙、聚会等活动，让年轻人真诚的交流起来，探索自我和他人</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--gym">
                  <div class="list-text">
                    <p class="title">706健身房</p>
                    <p class="text">这个是706深藏在20楼顶层的一个健身小空间，我们希望通过这个项目，让706的参与者有能力追求智力和体力的双重卓越</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="small">
              <div class="list-inner">
                <div class="list-content list-content--exhibit">
                  <div class="list-text">
                    <p class="title">706展览空间</p>
                    <p class="text">这个是706面向独立艺术家和美院等相关专业的学生提供的一个小型展览空间，我们倡导跨界的展览形式，强调多种形式的展览模式</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeIntro'
}
</script>

<style scoped lang="less">
@import ~"@/assets/style/variables";
.home__intro {
  margin-top: 617px;
  background: #fff;

  &__inner {
    max-width: 1920px;
    margin: 0 auto;
    .card {
      position: relative;
      top: -75px;
      display: flex;
      width: 900px;
      height: 430px;
      margin: 0 auto;
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
      .left {
        flex: 0 0 430px;
        background: no-repeat url("~@/assets/images/Home/sec-02.png") center /
          cover;
      }

      .right {
        flex: 1 1;
        padding: 39px 60px 0 38px;
        .title {
          margin-bottom: 33px;
          font-size: @--font-size-medium;
          font-weight: bold;
        }
        .text {
          line-height: 20px;
          &:nth-child(n + 2) {
            margin-top: 28px;
          }
        }
        .btn {
          width: 117px;
          margin-top: 97px;
          margin-left: auto;
          padding: 13px 0;
          line-height: 20px;
          text-align: center;
          background: #0b800b;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .content {
      margin: -32px auto 38px auto;
      width: 900px;
      box-sizing: border-box;
      padding-left: 66px;
      .title {
        position: relative;
        height: 102px;
        line-height: 102px;
        font-size: @--font-size-ex-large;
        font-weight: bold;
        &::after {
          content: "";
          position: absolute;
          width: 92px;
          height: 7px;
          left: 0;
          bottom: 0;
          background: #0b800b;
        }
      }
      .text {
        width: 526px;
        margin-top: 25px;
        line-height: 20px;
        color: #555;
        &.active {
          color: #000;
        }
      }
    }

    .list {
      display: flex;
      flex-wrap: nowrap;
      max-width: 1920px;
      margin-top: 81px;
      .left,
      .right {
        display: flex;
        flex: 1 1 50%;
        .col-1 {
          display: flex;
          flex-wrap: wrap;
          flex: 1 1;

          .small {
            flex: 0 0 100%;
          }
        }
        .col-2 {
          display: flex;
          flex-wrap: wrap;
          flex: 2 1;
          .small {
            flex: 0 0 50%;
          }
        }
        .big,
        .small {
          position: relative;
          box-sizing: border-box;
          padding: 4px;
        }
        .big {
          flex: 0 0 100%;
        }
      }
      &-inner {
        position: relative;
        padding-top: 100%;
        cursor: pointer;
        &:hover {
          .list-content {
            &::before {
              background: rgba(0, 0, 0, 0.5);
            }
            .list-text {
              opacity: 1;
            }
          }
        }
      }

      &-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: no-repeat center / cover;
        color: #fff;
        text-align: center;

        &--canteen {
          background-image: url("~@/assets/images/Home/intro-canteen.png");
        }

        &--coffee {
          background-image: url("~@/assets/images/Home/intro-coffee.png");
        }

        &--exhibit {
          background-image: url("~@/assets/images/Home/intro-exhibit.png");
        }

        &--gym {
          background-image: url("~@/assets/images/Home/intro-gym.png");
        }

        &--hotel {
          background-image: url("~@/assets/images/Home/intro-hotel.png");
        }

        &--labor {
          background-image: url("~@/assets/images/Home/intro-labor.png");
        }

        &--library {
          background-image: url("~@/assets/images/Home/intro-library.png");
        }

        &--living {
          background-image: url("~@/assets/images/Home/intro-living.png");
        }

        &--pub {
          background-image: url("~@/assets/images/Home/intro-pub.png");
        }

        &--rooftop {
          background-image: url("~@/assets/images/Home/intro-rooftop.png");
        }

        &--salon {
          background-image: url("~@/assets/images/Home/intro-salon.png");
        }

        &--theatre {
          background-image: url("~@/assets/images/Home/intro-theatre.png");
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          transition: background 0.2s;
        }
        .title {
          font-size: 15px;
          line-height: 17px;
        }
        .text {
          width: 80%;
          margin: 24px auto 0 auto;
          font-size: 10px;
          line-height: 17px;
        }
      }

      &-text {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
  }
}
</style>
