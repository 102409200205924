import { render, staticRenderFns } from "./ActivityPhotoUploader.vue?vue&type=template&id=584637bc&scoped=true&"
import script from "./ActivityPhotoUploader.vue?vue&type=script&lang=js&"
export * from "./ActivityPhotoUploader.vue?vue&type=script&lang=js&"
import style0 from "./ActivityPhotoUploader.vue?vue&type=style&index=0&id=584637bc&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584637bc",
  null
  
)

export default component.exports